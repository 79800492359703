//Models
import AppModel from './App/model.js';

const showAlert = AppModel.showAlert;

$(document).ready(function () {

    //handling registration form type switching
    $("#toggle").click(function () {
        const $toggleSwitch = $("#toggle-switch");
        const isActive = $toggleSwitch.hasClass("left-1");
        const agencyText = $toggleSwitch.data("agency-text");
        const ownerText = $toggleSwitch.data("owner-text");

        if (isActive) {
            $toggleSwitch.removeClass("left-1").addClass("toggle-translate").text(agencyText);
            $('#owner-register').addClass('hidden');
            $('#agency-register').removeClass('hidden');
        } else {
            $toggleSwitch.removeClass("toggle-translate").addClass("left-1").text(ownerText);
            $('#owner-register').removeClass('hidden');
            $('#agency-register').addClass('hidden');
        }
    });

    $('#prev-register-tab').on('click', function () {
        $('#enter-password-step').hide();
        $('#enter-basic-info-step').fadeIn('slow');
    });

    $('#next-register-tab').on('click', function () {
        const $form = $(this).closest('form');
        
        const email = $form.find('#email').val();
        const firstName = $form.find('#name').val();
        const lastName = $form.find('#last_name').val();
        let error = false;
        
        if(email == ''){
            $form.find('#email_error').append("<li>L'adresse électronique est requise</li>");
            error = true;
        }

        if(firstName == ''){
            $form.find('#name_error').append("<li>Le prénom est requis</li>");
            error = true;
        }

        if(lastName == ''){
            $form.find('#last_name_error').append("<li>Le nom de famille est requis</li>");
            error = true;
        }

        if(!error){
            $('#enter-basic-info-step').hide();
            $('#enter-password-step').fadeIn('slow');
            $form.find('.error').empty();
        }
    });

    //handling sending registration request form submission for agency
    $('#agency-register-form').on('submit', function (e) {
        e.preventDefault();

        const $form = $(this);
        const url = $form.attr('action');

        const payload = $form.serialize();
        const $submitButton = $form.find('button[type="submit"]');
        const submitButtonText = $submitButton.data('sending-text') ?? 'Soumettre ...';

        // clear all validation errors
        $form.find('.error').empty();

        $submitButton.text(submitButtonText);
        $submitButton.prop('disabled', true);

        grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute('6LfbttsqAAAAAJedd1gV75WsbO8h7I3h82RhJBuG', {action: 'SEND_REGISTRATION_REQUEST'});
            $.ajax({
                headers: { 'X-CSRF-TOKEN': $form.find('input[name="_token"]').val() },
                url: url,
                type: 'POST',
                data: payload  + `&g-recaptcha-response=${token}` + `&action=SEND_REGISTRATION_REQUEST`,
                dataType: 'json',
                context: this,
                success: function (response) {
                    showAlert(response.message);   
                    $form.find('input').val('');    
                    $form.find('textarea').val('');         
                },
                error: function(error) {
                    const errors = error.responseJSON.errors;
    
                    // loop through validation errors
                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            const errorMessages = errors[key];
                            const $errorsList = $form.find(`#${key}_error`);
                            $errorsList.empty();
                            errorMessages.forEach(message => {
                                $errorsList.append(`<li>${message}</li>`);
                            });
                        }
                    }
                },
                complete: function(){
                    $submitButton.text($submitButton.attr('aria-label'));
                    $submitButton.prop('disabled', false);
                }
            });
        });

    });

    //handling registration form submission for owner
    $('#owner-register-form').on('submit', function (e) {
        e.preventDefault();
        const $form = $(this);
        const action = 'REGISTRATION';
        const url = $form.attr('action');
        
        const payload = $form.serialize();
        const $submitButton = $form.find('input[type="submit"]');
        const submitButtonText = $submitButton.data('sending-text') ?? 'Soumettre ...';        

        // clear all validation errors
        $form.find('.error').empty();

        $submitButton.val(submitButtonText);
        $submitButton.prop('disabled', true);

        grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute('6LfbttsqAAAAAJedd1gV75WsbO8h7I3h82RhJBuG', {action});
            $.ajax({
                headers: { 'X-CSRF-TOKEN': $form.find('input[name="_token"]').val() },
                url: url,
                type: 'POST',
                data: payload + `&g-recaptcha-response=${token}` + `&action=REGISTRATION`,
                dataType: 'json',
                context: this,
                success: function (response) {
                    window.location.href = response.redirect_url;
                },
                error: function(error) {
                    const errors = error.responseJSON.errors;
    
                    // loop through validation errors
                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            const errorMessages = errors[key];
                            const $errorsList = $form.find(`#${key}_error`);
                            $errorsList.empty();
                            errorMessages.forEach(message => {
                                $errorsList.append(`<li>${message}</li>`);
                            });
                        }
                    }

                    //Handling showing the first error in Multi Step Register Form
                    if ($('.second-stage-input').find('.error').length > 0 && $('.first-stage-input').find('.error').length == 0) {
                        $('#enter-basic-info-step').hide();
                        $('#enter-password-step').show();
                    } else {
                        $('#enter-basic-info-step').show();
                        $('#enter-password-step').hide();
                    }
                },
                complete: function(){
                    $submitButton.val($submitButton.attr('aria-label'));
                    $submitButton.prop('disabled', false);
                }
            });
        });

    });
    
    //handling login form submission
    $('#login-form').on('submit', function (e) {
        e.preventDefault();
        const $form = $(this);
        const action = 'LOGIN';
        const url = $form.attr('action');
        
        const payload = $form.serialize();
        const $submitButton = $form.find('input[type="submit"]');
        const submitButtonText = $submitButton.data('sending-text') ?? 'Soumettre ...';        

        // clear all validation errors
        $form.find('.error').empty();

        $submitButton.val(submitButtonText);
        $submitButton.prop('disabled', true);

        grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute('6LfbttsqAAAAAJedd1gV75WsbO8h7I3h82RhJBuG', {action});
            $.ajax({
                headers: { 'X-CSRF-TOKEN': $form.find('input[name="_token"]').val() },
                url: url,
                type: 'POST',
                data: payload + `&g-recaptcha-response=${token}` + `&action=${action}`,
                dataType: 'json',
                context: this,
                success: function (response) {
                    if(response.status === 401){
                        $form.find(`#email_error`).append(`<li>${response.message}</li>`);
                    }else{
                        window.location.href = response.redirect_url;
                    }
                },
                error: function(error) {
                    const errors = error.responseJSON.errors;
    
                    // loop through validation errors
                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            const errorMessages = errors[key];
                            const $errorsList = $form.find(`#${key}_error`);
                            $errorsList.empty();
                            errorMessages.forEach(message => {
                                $errorsList.append(`<li>${message}</li>`);
                            });
                        }
                    }
                },
                complete: function(){
                    $submitButton.val($submitButton.attr('aria-label'));
                    $submitButton.prop('disabled', false);
                }
            });
        });

    });

});